<template>
  <div>
    <CCard class="col-lg-10">
      <CCardHeader>{{
        messages.userManager.newUser.toUpperCase()
      }}</CCardHeader>
      <CForm
        @submit.prevent="handleSubmit"
        id="registerUserForm"
        novalidate="true"
      >
        <CCardBody>
          <CRow form>
            <div :class="firstRowClass">
              <CInput :label="messages.userManager.name" v-model="name" />
              <p
                v-if="errorBlocks.nameRequired && name.length === 0"
                class="error-message"
              >
                {{ messages.validation.required }}
              </p>
            </div>
            <div :class="firstRowClass">
              <CInput :label="messages.userManager.email" v-model="email" />
              <p v-if="!isValidEmail && !!emailValidated" class="error-message">
                {{ messages.validation.emailError }}
              </p>
              <p
                v-if="errorBlocks.emailRequired && email.length === 0"
                class="error-message"
              >
                {{ messages.validation.required }}
              </p>
            </div>
            <CSelect
              :label="messages.userManager.language"
              :options="languageOptions"
              :value.sync="selectedLanguage"
              @change="setLanguage"
              v-if="showLangList"
              class="col-lg-2"
            />
          </CRow>
          <CRow form>
            <div class="col-lg-4">
              <CSelect
                :label="messages.userManager.profile"
                :options="profileOptions"
                :value.sync="selectedProfile"
                @change="setProfile"
              />
              <p v-if="errorBlocks.profileRequired" class="error-message">
                {{ messages.validation.required }}
              </p>
            </div>
            <CSelect
              :label="messages.userManager.businessName"
              :options="concessionOptions"
              :value.sync="selectedConcession"
              @change="setConcession"
              v-show="showConcessionList"
              class="col-lg-8"
            />
            <CSelect
              label="ASPM"
              :options="aspmOptions"
              :value.sync="selectedAspm"
              @change="setAspm"
              v-show="showAspmList"
              class="col-lg-8"
            />
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton type="submit" color="primary" :block="true" class="col-lg-4">
            {{ messages.userManager.submitButton.toUpperCase() }}
          </CButton>
        </CCardFooter>
      </CForm>
    </CCard>
    <CModal
      v-if="responseModal"
      :title="responseModal.title"
      :color="responseModal.color"
      :show.sync="responseModalShow"
      class="modal-vertical-centered"
    >
      <p>{{ responseModal.message }}</p>
      <span slot="footer">
        <CButton :color="responseModal.color" @click="responseModalShow = false"
          >Aceptar</CButton
        >
      </span>
    </CModal>
  </div>
</template>

<script>
import Api from "@/services/Api";
import validation from "@/utils/validation";
import filterHelpers from "@/helpers/filterHelpers";
import langStr from "@/utils/langStr";

export default {
  name: "RegisterUser",
  data() {
    return {
      userProfileId: null,
      userConcessionCode: null,
      messages: null,
      isValid: false,
      name: "",
      email: "",
      emailValidated: false,
      errorBlocks: {
        nameRequired: false,
        emailRequired: false,
        profileRequired: false,
      },
      profileOptions: [],
      selectedProfile: null,
      showConcessionList: false,
      concessionOptions: [],
      selectedConcession: null,
      showLangList: false,
      selectedLanguage: 1,
      explicitLangRequired: false,
      aspmOptions: [],
      showAspmList: false,
      selectedAspm: null,
      responseModal: null,
      responseModalShow: false,
    };
  },
  computed: {
    languageOptions() {
      return [
        { value: 1, label: this.messages.userManager.spanish },
        { value: 2, label: this.messages.userManager.portuguese },
      ];
    },
    concessionsLoaded() {
      return this.concessionOptions.length > 0;
    },
    aspmsLoaded() {
      return this.aspmOptions.length > 0;
    },
    isValidEmail() {
      return validation.rules().emailStrict(this.email);
    },
    isEmpty(value) {
      return validation.rules().required(value);
    },
    firstRowClass() {
      return this.showLangList ? "col-lg-5" : "col-lg-6";
    },
    thankyouModal() {
      return {
        ok: {
          title: this.messages.userManager.newUser.toUpperCase(),
          color: "success",
        },
        ko: {
          title: this.messages.common.error.toUpperCase(),
          color: "danger",
        },
      };
    },
  },
  methods: {
    required(date) {
      return val && val.length >= 3;
    },
    createProfilesList(profileId) {
      const list = [{ value: "", label: "-- Selecciona --" }];
      const profileKeys = Object.keys(this.messages.userManager.profileNames);
      console.log("profileKeys", profileKeys);
      const filteredProfileKeys = profileKeys.filter((key) => {
        if (key === "20") {
          return parseInt(key) >= profileId;
        } else {
          return parseInt(key) >= profileId;
        }
      });
      filteredProfileKeys.forEach((elem) => {
        list.push({
          value: elem,
          label: this.messages.userManager.profileNames[elem],
        });
      });
      console.log("list = ", list);
      this.profileOptions = list;
    },
    setProfile(event) {
      const value = event.target.value !== "" ? event.target.value : null;
      this.selectedProfile = value ? parseInt(event.target.value) : null;
      // Validation
      this.validateProfile(this.selectedProfile);
      // Show Concession List or not
      this.haveToDisplayConcessionList(this.selectedProfile);
      // Show ASPM list or not
      this.haveToDisplayAspmList(this.selectedProfile);
    },
    validateProfile(value) {
      if (!validation.rules().required(value)) {
        this.errorBlocks.profileRequired = true;
      } else {
        this.errorBlocks.profileRequired = false;
      }
    },
    haveToDisplayConcessionList(userProfileId) {
      if (userProfileId >= 20) {
        // Make API query if concession list isn't loaded
        if (!this.concessionsLoaded) {
          this.getConcessionList().then((results) => {
            this.concessionOptions = filterHelpers.concessionOptions(results);
            this.showConcessionSelect();
          });
        } else {
          this.showConcessionSelect();
        }
      } else {
        this.showConcessionList = false;
        this.selectedConcession = null;
      }
    },
    showConcessionSelect() {
      if (this.userProfileId <= 10) {
        this.showConcessionList = true;
        this.selectedConcession = this.concessionOptions[0].value;
      }
    },
    async getConcessionList() {
      const results = await Api.sendGet("sys/filter/concessions");
      return results.data.data;
    },
    setConcession(ev) {
      this.selectedConcession = ev.target.value;
    },
    haveToDisplayAspmList(userProfileId) {
      if (userProfileId === 10) {
        if (!this.aspmsLoaded) {
          this.getAspmList().then((results) => {
            this.aspmOptions = results;
            this.showAspmSelect();
          });
        } else {
          this.showAspmSelect();
        }
      } else {
        this.showAspmList = false;
        this.selectedAspm = null;
      }
    },
    showAspmSelect() {
      this.showAspmList = true;
      this.selectedAspm = this.aspmOptions[0];
    },
    async getAspmList() {
      const results = await Api.sendGet("sys/filter/aspms");
      return results.data.data;
    },
    setAspm(ev) {
      this.selectedAspm = ev.target.value;
    },
    haveToDisplayLangSelect(userProfileId) {
      if (userProfileId < 10) {
        this.showLangList = true;
        this.explicitLangRequired = true;
      }
    },
    setLanguage(ev) {
      this.selectedLanguage = parseInt(ev.target.value);
    },
    formValidation(user) {
      if (!validation.rules().required(user.name)) {
        this.errorBlocks.nameRequired = true;
      }
      if (!validation.rules().required(user.email)) {
        this.errorBlocks.emailRequired = true;
      }
      if (!validation.rules().required(user.profileId)) {
        this.errorBlocks.profileRequired = true;
      }
      if (
        !this.isValidEmail ||
        !validation.rules().required(user.email) ||
        !validation.rules().required(user.name) ||
        !validation.rules().required(user.profileId)
      ) {
        return false;
      } else {
        return true;
      }
    },
    handleSubmit() {
      this.emailValidated = true;
      const newUser = {
        name: this.name,
        email: this.email,
        languageId: this.selectedLanguage,
        profileId: this.selectedProfile,
      };

      if (this.selectedConcession)
        newUser.concessionCode = this.selectedConcession;

      if (!this.explicitLangRequired && this.showConcessionList) {
        newUser.languageId = langStr.getByConcessionCode(
          this.selectedConcession
        ).id;
      }
      if (this.selectedAspm) newUser.aspm = this.selectedAspm;

      console.log("NEW USER", newUser);

      if (this.formValidation(newUser)) {
        this.makeApiRequest(newUser)
          .then((response) => {
            switch (response.type) {
              case "ok":
                this.responseModal = {
                  ...this.thankyouModal.ok,
                  message: this.messages.userManager.responseOK(
                    response.data.name,
                    response.data.email
                  ),
                };
                this.responseModalShow = true;
                break;
              case "ko":
                this.responseModal = {
                  ...this.thankyouModal.ko,
                  message: this.messages.errors[response.data]
                    ? this.messages.errors[response.data]
                    : this.messages.errors["400"],
                };
                this.responseModalShow = true;
                break;
            }
          })
          .catch((error) => {
            console.error(error);
            this.responseModal = {
              ...this.thankyouModal.ko,
              message: this.messages.errors["400"],
            };
            this.responseModalShow = true;
          });
      }
    },
    makeApiRequest(data) {
      return Api.sendPost("user/reg", data).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            return {
              type: "ok",
              data: response.data.data,
            };
          } else {
            return {
              type: "ko",
              data: response.data.code,
            };
          }
        }
      });
    },
  },
  created() {
    const user = this.$store.getters.getCurrentUser;
    this.userProfileId = user.profileId;
    this.selectedConcession =
      this.userProfileId >= 20 ? user.concessionCode : null;
    this.messages = this.$store.getters.getLangMessages;
    this.createProfilesList(this.userProfileId);
    this.haveToDisplayLangSelect(this.userProfileId);
  },
};
</script>
