var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showLoader)?_c('CRow',{staticClass:"justify-content-center"},[_c('Loader',{attrs:{"width":"100px","height":"100px","fill-color":"#c2c2c2","cssClass":"fadeIn anim-speed--1s"}})],1):_vm._e(),(!_vm.showLoader)?_c('CCard',[_c('CCardHeader',{staticClass:"flex flex--space-between"},[_vm._v(" "+_vm._s(_vm.messages.userManager.listTitle)+" "),_c('div',[_c('CButton',{staticStyle:{"margin-right":"1rem"},attrs:{"color":"success","to":{ name: this.messages.userManager.newUser }}},[_c('CIcon',{staticStyle:{"margin":"0 0.2rem 0 0"},attrs:{"name":"cil-user-plus"}}),_vm._v(" "+_vm._s(_vm.messages.userManager.newUser)+" ")],1),_c('ExportExcelButton',{attrs:{"tableId":_vm.tableId},on:{"export-table":function($event){return _vm.exportTableToExcel($event)}}})],1)]),_c('CCardBody',[_c('CDataTable',{ref:_vm.tableId,attrs:{"items":_vm.tableData,"fields":_vm.fields,"hover":"","outlined":"","border":"","clickableRows":"","columnFilter":true,"sorter":true,"tableFilter":{
          label: _vm.messages.tables.filter,
          placeholder: `${_vm.messages.tables.search}...`,
        },"cleaner":"","noItemsView":{
          noItems: _vm.messages.tables.noItems,
          noResults: `${_vm.messages.tables.noResults}`,
        },"items-per-page":25,"pagination":""},scopedSlots:_vm._u([{key:"delete",fn:function({ item }){return [_c('td',[(item.userId !== _vm.currentUserId)?_c('CButton',{attrs:{"color":"warning"},on:{"click":function($event){return _vm.showWarning(`${item.name}`, `${item.userId}`)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}}),_vm._v(" "+_vm._s(_vm.messages.userManager.deleteUser))],1):_vm._e()],1)]}}],null,false,564247429)})],1)],1):_vm._e(),_c('CModal',{staticClass:"modal-vertical-centered",attrs:{"title":_vm.warningModal.title,"color":"warning","show":_vm.warningModal.show},on:{"update:show":function($event){return _vm.$set(_vm.warningModal, "show", $event)}}},[_c('p',[_vm._v(_vm._s(_vm.warningModal.message))]),_c('span',{attrs:{"slot":"footer"},slot:"footer"},[_c('CButton',{staticStyle:{"margin-right":"1rem"},attrs:{"color":"secondary"},on:{"click":function($event){_vm.warningModal.show = false}}},[_vm._v("Cancelar")]),_c('CButton',{attrs:{"color":"warning"},on:{"click":function($event){return _vm.deleteUser(_vm.warningModal.btnDelete)}}},[_vm._v("Aceptar")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }