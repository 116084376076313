<template>
  <div>
    <CRow v-if="showLoader" class="justify-content-center">
      <Loader
        width="100px"
        height="100px"
        fill-color="#c2c2c2"
        cssClass="fadeIn anim-speed--1s"
      />
    </CRow>
    <CCard v-if="!showLoader">
      <CCardHeader class="flex flex--space-between">
        {{ messages.userManager.listTitle }}
        <div>
          <CButton
            color="success"
            style="margin-right: 1rem"
            :to="{ name: this.messages.userManager.newUser }"
          >
            <CIcon name="cil-user-plus" style="margin: 0 0.2rem 0 0" />
            {{ messages.userManager.newUser }}
          </CButton>
          <ExportExcelButton
            :tableId="tableId"
            @export-table="exportTableToExcel($event)"
          />
        </div>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          :ref="tableId"
          :items="tableData"
          :fields="fields"
          hover
          outlined
          border
          clickableRows
          :columnFilter="true"
          :sorter="true"
          :tableFilter="{
            label: messages.tables.filter,
            placeholder: `${messages.tables.search}...`,
          }"
          cleaner
          :noItemsView="{
            noItems: messages.tables.noItems,
            noResults: `${messages.tables.noResults}`,
          }"
          :items-per-page="25"
          pagination
        >
          <template #delete="{ item }">
            <td>
              <CButton
                color="warning"
                v-if="item.userId !== currentUserId"
                @click="showWarning(`${item.name}`, `${item.userId}`)"
                ><CIcon name="cil-trash" />
                {{ messages.userManager.deleteUser }}</CButton
              >
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
    <CModal
      :title="warningModal.title"
      color="warning"
      :show.sync="warningModal.show"
      class="modal-vertical-centered"
    >
      <p>{{ warningModal.message }}</p>
      <span slot="footer">
        <CButton
          color="secondary"
          @click="warningModal.show = false"
          style="margin-right: 1rem"
          >Cancelar</CButton
        >
        <CButton color="warning" @click="deleteUser(warningModal.btnDelete)"
          >Aceptar</CButton
        >
      </span>
    </CModal>
  </div>
</template>

<script>
import Api from "@/services/Api";
import TableDataToExcel from "@/helpers/TableDataToExcel";
import Loader from "@/components/atoms/Loader";

const ExportExcelButton = () =>
  import(
    /* webpackChunkName: "exportExcelButton" */ "@/components/molecules/ExportExcelButton"
  );

export default {
  name: "UserManager",
  components: {
    ExportExcelButton,
    Loader,
  },
  data() {
    return {
      currentUserId: null,
      messages: null,
      showLoader: true,
      tableId: "UserList",
      clickable: false,
      warningModal: {
        show: false,
        title: "",
        message: "",
        btnDelete: null,
      },
      initialTableData: [],
    };
  },
  computed: {
    profileId() {
      return this.$store.getters.getCurrentUserProfile;
    },
    fields() {
      const result = [
        { key: "name", label: this.messages.userManager.name.toUpperCase() },
        {
          key: "email",
          label: this.messages.userManager.email.toUpperCase(),
        },
        {
          key: "profile",
          label: this.messages.userManager.profile.toUpperCase(),
        },
      ];
      if (this.profileId !== 20) {
        result.push({
          key: "concessionCode",
          label: this.messages.userManager.concessionCode.toUpperCase(),
        });
        result.push({
          key: "businessName",
          label: this.messages.userManager.businessName.toUpperCase(),
        });
      }
      result.push({
        key: "delete",
        label: "",
      });
      return result;
    },
    // Computed property with getter and setter for update table Data
    tableData: {
      // getter
      get() {
        return this.initialTableData;
      },
      // setter
      set(newData) {
        return newData;
      },
    },
  },
  methods: {
    processDataTable() {
      this.getUserList().then((results) => {
        results.forEach((elem) => {
          if (elem.userId === this.currentUserId) {
            this.tableData[0] = this.createRow(elem);
          } else {
            this.tableData.push(this.createRow(elem));
          }
        });
        this.showLoader = false;
      });
    },
    createRow(elem) {
      const row = {};
      row.userId = elem.userId;
      row.name = elem.name;
      row.email = elem.email;
      row.profile = this.messages.userManager.profileNames[elem.profileId];
      if (Object.keys(elem).includes("concession")) {
        row.concessionCode = elem.concession.concessionCode;
        row.businessName = elem.concession.businessName;
      } else {
        row.concessionCode = "";
        row.businessName = "";
      }
      return row;
    },
    async getUserList() {
      const results = await Api.sendGet("user/list");
      return results.data.data;
    },
    showWarning(userName, userId) {
      this.warningModal.show = true;
      this.warningModal.title = "ATENCIÓN";
      this.warningModal.message = `¿Está seguro de que quiere eliminar el usuario ${userName}?`;
      this.warningModal.btnDelete = userId;
    },
    deleteUser(userId) {
      const url = `user/delete/${userId}`;
      this.deleteRequest(url).then(() => {
        this.showLoader = true;
        // Empty the dataTable property with setter to force update DataTable component
        this.tableData = this.tableData.splice(0);
        // Reload de data Table and reset the dataTable property
        this.processDataTable();
      });
      this.warningModal.show = false;
    },
    async deleteRequest(url) {
      const results = await Api.sendDelete(url);
      return results;
    },
    exportTableToExcel(tableId) {
      const list = this.$refs[tableId]._props.items.map((elem) => {
        delete elem.userId;
        return elem;
      });
      TableDataToExcel(
        this.$refs[tableId]._props.fields,
        list,
        this.messages.userManager.listTitle
      );
    },
  },
  created() {
    const user = this.$store.getters.getCurrentUser;
    this.currentUserId = user.userId;
    this.messages = this.$store.getters.getLangMessages;
    this.processDataTable();
  },
};
</script>
